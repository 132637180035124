import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Section } from 'components/Layout'
import trackEvent from 'api/analytics'
import { LinkButton } from 'components/Shared'
import ReactRotatingText from 'react-rotating-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { media } from 'styled-bootstrap-grid'
import useWindowSize from '../../hooks/useGatsbyWindowSize'
import { getOutlink } from '../../utils/analyticsUtils'

const StyledAWSLogo = styled.img`
  width: 110px;
  ${media.lg`
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 16px;
  `}
`

const StyledAwsBadge = styled(GatsbyImage)`
    padding: 36px;
    width: 32px;
    height: 32px;
`


const StyledLogos = styled.div`
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    display: none;
    ${media.lg`
      display: flex;
    `}
`

const ButtonRow = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  ${media.lg`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  `}
`

const StyledRotatingText = styled(ReactRotatingText)`
  font-family: Exo;
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.title};
  @media (max-width: 420px) {
    font-size: 32px;
  }
`

const StyledSubtitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 20px;
  text-align: center;
  max-width: 750px;
  margin: auto;
  margin-top: 40px;
`

const PlayButton = styled.button`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.palette.primary.main};
  opacity: 0.9;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  &:hover {
    display: flex;
  }
`

const ImageContainer = styled.div`
  display: grid;
  justify-content: center;
  position: absolute;
  z-index: 30;
  top: 860px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc((100% - 48px)* 0.9);
  max-width: calc(1350px * 0.9);
  &:hover ${PlayButton}:not(:hover) {
    display: flex;
  }

  @media (max-width: 600px) {
    top: 800px;
    width: calc(100% - 48px);
  }
`

const ButtonNote = styled.p`
  text-align: center;
  font-size: 12px;
  margin-top: -12px;
`

const Title = styled.h1`
  margin-top: 40px;
  text-align: center;
  line-height: 60px;
  @media (max-width: 420px) {
    font-size: 32px;
  }
`

const PlayTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid white;
  border-radius: 2px;
`

const StyledIFrame = styled.iframe`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 1);
  width: calc((100vw - 48px)* 0.9);
  max-width: calc(1350px * 0.8);
  aspect-ratio: 16 / 9;
`

const YellowText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const ContentContainer = styled.div`
  height: 650px;
`

const AWSLink = styled.a`
  display: grid;
  justify-content: center;
`

const HeroSlice = ({ image, awsQualifiedImage }) => {
  const sliceContent = {
    titleVerbs: [
      'create',
      'manage',
      'personalize',
      'preview',
      'send',
      'publish',
    ],
    claimPrefix: 'Easily ',
    titleClaim: [
      <span>stunning AWS SES email templates </span>,
      <br />,
      <span>with the Semplates template editor</span>,
    ],
    subtitle: [
      <StyledSubtitle>
        Unlock the full potential of <YellowText>Amazon SES</YellowText> with{' '}
        <YellowText>Semplates&apos; email template editor.</YellowText>&nbsp;
        Design and publish personalized, responsive and branded emails with a
        few clicks.
      </StyledSubtitle>,
    ],
    imageAlt:
      'The Semplates template editor makes it easy to design personalized, responsive, and branded transactional email templates via drag and drop - no coding skills required.',
  }
  const theme = useTheme()
  const { width } = useWindowSize()

  const [showYoutubeVideo, setShowYoutubeVideo] = useState(false)

  const handleOpenYoutubeModal = () => {
    setShowYoutubeVideo(!showYoutubeVideo)
    // send backend request, that user clicked on play button
    trackEvent('video_started')
  }

  useEffect(() => {
    if (width < theme.breakpoints.lg) setShowYoutubeVideo(false)
  }, [width])

  const HeroContent = () => (
    <ContentContainer>
      <Title>
        {sliceContent.claimPrefix}
        <StyledRotatingText
          items={sliceContent.titleVerbs}
          color={theme.palette.secondary.main}
        />
        <br />
        {sliceContent.titleClaim}
      </Title>
      {sliceContent.subtitle}
      <ButtonRow>
        <div>
          <LinkButton
            size={width < theme.breakpoints.sm ? 'sm' : ''}
            label="Get started for free"
            to={getOutlink()}
          />
          <ButtonNote>No credit card needed!</ButtonNote>
        </div>
        <StyledLogos>
          <AWSLink href="https://aws.amazon.com/what-is-cloud-computing" target="_blank">
            <StyledAWSLogo
              src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
              alt="Powered by AWS Cloud Computing"
            />
          </AWSLink>
          <StyledAwsBadge image={getImage(awsQualifiedImage)} />
        </StyledLogos>
      </ButtonRow>
    </ContentContainer>
  )
  return (
    <Section bgStyle="dark" showWave>
      <div>
        <HeroContent />
        <ImageContainer>
          {showYoutubeVideo ? (
            <StyledIFrame
              src="https://www.youtube.com/embed/EzLPtkqbD3I?autoplay=1&modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&title=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="Semplates demo video"
            />
          ) : (
            <>
              <PlayButton onClick={handleOpenYoutubeModal}>
                <PlayTriangle />
              </PlayButton>
              <GatsbyImage image={image} alt={sliceContent.imageAlt} />
            </>
          )}
        </ImageContainer>
      </div>
    </Section>
  )
}

export default HeroSlice
